import { BASE_URL } from '$/modules/baseUrl'

export default function getRelativeUrl(url: string) {
  const urlObj = new URL(url, BASE_URL)

  let maybeSearchAndParams = ''

  if (urlObj.search.length) {
    maybeSearchAndParams = urlObj.search
  }

  if (urlObj.hash.length) {
    maybeSearchAndParams += urlObj.hash
  }

  return `${urlObj.pathname}${maybeSearchAndParams}`
}
