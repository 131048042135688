import { useState, useEffect } from 'react'

export function useIsSsr() {
  const [isSsr, setIsSsr] = useState(true)

  useEffect(function onMount() {
    setIsSsr(false)
  }, [])

  return isSsr
}
