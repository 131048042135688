type DialogConfig<Id extends string = string, Href extends string = string> = {
  id: Id
  href: Href
}

export const DIALOG_CFT_SALES: DialogConfig<'cft_sales', '/teams/sales'> = {
  id: 'cft_sales',
  href: '/teams/sales',
} as const

export const DIALOG_CONFIGS_BY_ID = {
  [DIALOG_CFT_SALES.id]: DIALOG_CFT_SALES,
}

export const DIALOG_CONFIGS_BY_HREF = {
  [DIALOG_CFT_SALES.href]: DIALOG_CFT_SALES,
}

export type PossibleDialogHref = keyof typeof DIALOG_CONFIGS_BY_HREF

export function isValidDialogHref(href?: string): href is PossibleDialogHref {
  return Boolean(DIALOG_CONFIGS_BY_HREF[href as PossibleDialogHref])
}
