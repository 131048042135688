'use client'

import clsx from 'clsx'
import { forwardRef } from 'react'

import { LinkWrapper } from '$/components/LinkWrapper'

import { Slot } from './Slot'

export type ButtonProps = {
  pallet?: 'blue' | 'gray'
  variation?: 'primary' | 'secondary' | 'border' | 'borderless'
  size: 'small' | 'medium' | 'large'
  startSlot?: React.ReactNode
  endSlot?: React.ReactNode
  children: React.ReactNode
  href?: HTMLAnchorElement['href']
  target?: HTMLAnchorElement['target']
  rel?: HTMLAnchorElement['rel']
  fullWidth?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> &
  Pick<React.ComponentProps<typeof LinkWrapper>, 'allowHttpLinks'>

const buttonVariations = {
  blue: {
    primary: clsx(
      'text-white',
      'bg-circuitBlue-600  hover:bg-circuitBlue-700',
      'disabled:bg-circuitBlue-10 disabled:text-circuitBlue-100',
    ),
    secondary: clsx(
      'text-circuitBlue-600',
      'bg-circuitBlue-50 hover:bg-circuitBlue-100',
      'disabled:bg-circuitBlue-50 disabled:text-circuitBlue-200',
    ),
    border: clsx(
      'text-circuitBlue-600 border border-circuitBlue-500',
      'bg-white hover:bg-circuitBlue-50',
      'disabled:bg-circuitBlue-10 disabled:text-circuitBlue-100 disabled:border-circuitBlue-100',
    ),
    borderless: clsx(
      'text-circuitBlue-600',
      'bg-white hover:bg-circuitBlue-50',
      'disabled:bg-white disabled:text-circuitBlue-100',
    ),
  },
  gray: {
    primary: clsx(
      'text-white',
      'bg-gray-900 hover:bg-gray-600',
      'disabled:bg-gray-600 disabled:text-gray-100',
    ),
    secondary: clsx(
      'text-gray-600',
      'bg-gray-50 hover:bg-gray-100',
      'disabled:bg-gray-10 disabled:text-gray-200',
    ),
    border: clsx(
      'text-gray-600 border border-gray-200',
      'bg-white hover:bg-gray-50',
      'disabled:text-gray-200 disabled:bg-white disabled:border-gray-100',
    ),
    borderless: clsx(
      'text-gray-600',
      'bg-white hover:bg-gray-50',
      'disabled:text-gray-200 disabled:bg-white',
    ),
  },
} as const

// if icon is present, the padding gets padding-3px on the side
const sizeMap: Record<string, SizeClasses> = {
  noSlots: {
    small: clsx('px-1.5 py-px'),
    medium: clsx('px-4 py-3 space-x-1'),
    large: 'px-6 py-4 space-x-2',
  },
  startSlot: {
    small: clsx('px-1.5 py-px'),
    medium: clsx('pr-4 pl-[0.8125rem] py-3 space-x-1'),
    large: clsx('pr-6 pl-[1.3125rem] py-4 space-x-2'),
  },
  endSlot: {
    small: clsx('px-1.5 py-px'),
    medium: clsx('pl-4 pr-[0.8125rem] py-3 space-x-1'),
    large: clsx('pl-6 pr-[1.3125rem] py-4 space-x-2'),
  },
  iconOnly: {
    small: clsx('px-1 py-1'),
    medium: clsx('p-3.5'),
    large: clsx('p-[1.0625rem]'),
  },
  bothSlots: {
    small: clsx('px-1.5 py-px'),
    medium: clsx('pl-[0.8125rem] pr-[0.8125rem] py-3 space-x-1'),
    large: clsx('pl-[1.3125rem] pr-[1.3125rem] py-4 space-x-2'),
  },
} as const

function isString(value: ButtonProps['children']): value is string {
  return typeof value === 'string'
}

function getButtonSizes({
  size,
  startSlot,
  endSlot,
  children,
}: Pick<ButtonProps, 'size' | 'startSlot' | 'endSlot' | 'children'>) {
  const hasStartSlot = !!startSlot && !endSlot
  const hasEndSlot = !!endSlot && !startSlot
  const hasBothSlots = !!endSlot && !!startSlot
  const childrenIsSvg = !isString(children)

  const key = childrenIsSvg
    ? 'iconOnly'
    : hasStartSlot
      ? 'startSlot'
      : hasEndSlot
        ? 'endSlot'
        : hasBothSlots
          ? 'bothSlots'
          : 'noSlots'

  return sizeMap[key][size]
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(function Button(
  {
    pallet = 'blue',
    variation = 'primary',
    size,
    startSlot = null,
    endSlot = null,
    type = 'button',
    children,
    ...props
  },
  ref,
) {
  const sizeClasses = getButtonSizes({ size, startSlot, endSlot, children })
  const palletClasses = buttonVariations[pallet][variation]

  const { className: customClasses, fullWidth = false, ...rest } = props

  const Tag = props.href && !props.disabled ? LinkWrapper : 'button'

  const applyFullWidth = fullWidth ? 'w-full' : ''

  const typographyClasses =
    size === 'small'
      ? 'text-body-small-bold-website'
      : 'text-button-cta-website'

  const commonClasses = clsx(
    'rounded-lg group transition duration-100 ease-out inline-flex items-center justify-center',
  )

  return (
    <Tag
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      type={type}
      className={clsx(
        sizeClasses,
        palletClasses,
        commonClasses,
        typographyClasses,
        customClasses,
        applyFullWidth,
      )}
      {...rest}
    >
      {Boolean(startSlot) && (
        <Slot pallet={pallet} variation={variation} position="start">
          {startSlot}
        </Slot>
      )}
      {Boolean(children) && <span className="min-w-0">{children}</span>}
      {Boolean(endSlot) && (
        <Slot pallet={pallet} variation={variation} position="end">
          {endSlot}
        </Slot>
      )}
    </Tag>
  )
})

type SizeClasses = {
  small: string
  medium: string
  large: string
}
