'use client'

/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import { Suspense, forwardRef, lazy } from 'react'
import { usePathname } from 'next/navigation'
import { track } from '@amplitude/analytics-browser'

import { useIsSsr } from '$/modules/useIsSsr'
import { isValidDialogHref } from '$/modules/dialogConfigs'
import { linkResolver } from '$/services/prismic/linkResolver'
import { resolvePrismicLink } from '$/modules/resolvePrismicLink.client'
import type { LinkCard as LinkCardType } from '$/models/linkCard/types'

import type { DataSliceType } from '../types'
import type { HTMLAttributeAnchorTarget } from 'react'
import type { LinkField } from '@prismicio/client'

const LinkCard = lazy(() => import('../LinkCard'))

export type LinkWrapperProps = {
  rel?: string
  href?: string
  title?: string
  className?: string
  field?: LinkField | null
  children: React.ReactNode
  dataSliceType?: DataSliceType
  target?: HTMLAttributeAnchorTarget
  linkCardProps?: LinkCardType | null
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseDown?: React.MouseEventHandler<HTMLAnchorElement>
  'data-crp-event'?: 'install-cta'
} & Pick<Parameters<typeof resolvePrismicLink>[0], 'allowHttpLinks'>

/**
 * The base link component of the application.
 * Every anchor element should be rendered using this component.
 */
export const LinkWrapper = forwardRef<HTMLAnchorElement, LinkWrapperProps>(
  function LinkWrapper(props, ref) {
    const {
      rel,
      href,
      field,
      title,
      target,
      onClick,
      children,
      className,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      dataSliceType,
      linkCardProps,
      allowHttpLinks,
      ...rest
    } = props

    const anchorProps = resolvePrismicLink({
      rel,
      href,
      field,
      target,
      linkResolver,
      allowHttpLinks,
    })

    const isSsr = useIsSsr()
    const pathname = usePathname()

    if (!anchorProps.href) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <a
          data-slice-type={dataSliceType}
          {...anchorProps}
          ref={ref}
          title={title}
          role="button"
          tabIndex={0}
          onClick={onClick}
          className={className}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </a>
      )
    }

    function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
      if (linkCardProps) {
        stopPropagation(e)

        return
      }

      trackCRPCTAClick(e, pathname)

      onClick?.(e)
    }

    const renderedLink = (
      <Link
        data-slice-type={dataSliceType}
        {...rest}
        ref={ref}
        title={title}
        prefetch={false}
        rel={anchorProps.rel}
        className={className}
        data-prefetch={false}
        href={anchorProps.href}
        onMouseDown={onMouseDown}
        target={anchorProps.target}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
        scroll={isValidDialogHref(anchorProps.href) ? false : undefined}
      >
        {children}
      </Link>
    )

    if (linkCardProps && !isSsr) {
      const rendered = (
        <LinkCard linkCard={linkCardProps}>{renderedLink}</LinkCard>
      )

      return <Suspense fallback={rendered}>{rendered}</Suspense>
    }

    return renderedLink
  },
)

function trackCRPCTAClick(
  e: React.MouseEvent<HTMLAnchorElement>,
  pathname: string,
) {
  const amplitudeEvent = e.currentTarget.getAttribute('data-crp-event')
  const buttonText = e.currentTarget.innerText

  if (pathname.includes('route-planner') && amplitudeEvent) {
    track(amplitudeEvent, {
      label: buttonText,
    })
  }
}

/**
 * Stops the click event of the link to avoid a race
 * condition when unmounting the `LinkCard` after it
 * starts a route change.
 */
function stopPropagation(e: React.MouseEvent) {
  e.stopPropagation()
}
