import { asLink } from '@prismicio/client'

import type { LinkProps } from '$/components/types'

import getRelativeUrl from './getRelativeUrl'
import { noFollowLinks } from './noFollowLinks'
import { BROKEN_LINK_HTTP } from './errorCauses'

import type { LinkResolverFunction, LinkField } from '@prismicio/client'
import type { HTMLAttributeAnchorTarget } from 'react'

type Args = {
  rel?: string
  href?: string | null
  field?: LinkField | null
  target?: HTMLAttributeAnchorTarget
  linkResolver: LinkResolverFunction | undefined
  /**
   * Whether it should allow HTTP links.
   * This should be used only on non-indexed pages.
   */
  allowHttpLinks?: boolean
}

/**
 * This function is heavily based on the `PrismicLink` component from `@prismicio/react`.
 * We have a specific implementation for the client because the server makes some
 * relationship connections between documents.
 */
export function resolvePrismicLink(args: Args): Omit<LinkProps, 'label'> {
  let href: string | null | undefined

  if (args.href) {
    href = args.href
  } else if (args.field) {
    href = asLink(args.field, { linkResolver: args.linkResolver })
  }

  // Just in case we get here as `undefined` or `null`
  href = href ?? ''

  /**
   * Tells if the URL is a internal link or not
   */
  let isInternal = false

  // If the baseUrl of the href is our own domain or our dev env URL,
  // we remove it and pass only the relative path. This is useful when developing
  // and navigating on preview URLs.
  if (
    href.includes(process.env.PROD_BASE_URL as string) ||
    href.includes(process.env.DEV_BASE_URL as string)
  ) {
    href = getRelativeUrl(href)
    isInternal = true
  } else if (href.startsWith('/') || href.startsWith('#')) {
    isInternal = true
  }

  href = href.trim()

  if (href.length > 1 && href.endsWith('/')) {
    href = href.slice(0, -1)
  }

  const target =
    args.target ||
    (args.field && 'target' in args.field && args.field.target) ||
    (!isInternal && '_blank') ||
    undefined

  if (!args.allowHttpLinks && href.includes('http://')) {
    throw new Error(
      'All links must be https:// . ' + `Broken link found: ${href} `,
      {
        cause: new Error(BROKEN_LINK_HTTP),
      },
    )
  }

  const [hrefNoQueryString] = href.split('?')

  if (isInternal && hrefNoQueryString !== hrefNoQueryString.toLowerCase()) {
    throw new Error(`All internal links must be in lowercase. Link: ${href}`)
  }

  const rel = getRel({ href, target, relFromUser: args.rel })

  return {
    rel,
    href,
    target,
  }
}

type GetRelArgs = {
  href: string | undefined
  target: string | undefined
  relFromUser: string | undefined
}

function getRel(args: GetRelArgs) {
  const { href, target, relFromUser } = args

  const isNoFollowLink = href ? noFollowLinks.some(getTestHref(href)) : false

  if (isNoFollowLink) {
    return 'nofollow'
  }

  if (relFromUser) {
    return relFromUser
  }

  if (target === '_blank') {
    return 'noopener noreferrer'
  }
}

function getTestHref(href: string) {
  return function testHref(noFollowLink: string) {
    const noFollowWithScapedDots = noFollowLink.replace(/\./g, '.')

    const regex = new RegExp(`^(https://)?${noFollowWithScapedDots}`, 'i')

    return regex.test(href)
  }
}
