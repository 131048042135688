// https://docs.google.com/spreadsheets/u/2/d/1w8vZRS5va4Q6L7XVQindm9taFvAfAnseTzlzNkEGCw0/edit?usp=sharing
export const noFollowLinks = [
  'route4me.com',
  'upperinc.com',
  'badgermapping.com',
  'myrouteonline.com',
  'parcelmonitor.com',
  'my-package-tracking.com',
  'maptive.com',
  'routific.com',
  'ship24.com',
  'trackingmore.com',
  'parcelsapp.com',
  'ordertracker.com',
  'optimoroute.com',
  'getstraightaway.com',
  'routexl.com',
  'aftership.com',
  'calcurates.com',
  'packagetrackr.com',
  'onfleet.com',
  'stamps.com',
  'pkge.net',
  'trackapkg.com',
  'ridesharingdriver.com',
  'track24.net',
  'easyship.com',
  'bringg.com',
  'ems.post',
  'roadie.com',
  'usps.com',
  'postalytics.com',
  'goshare.co',
  'smartroutes.io',
  'uspostholdmail.com',
  'ddiwork.com',
  'usglobalmail.com',
  'roundtrip.ai',
  'parcelmonkey.com',
  'ridester.com',
  'prc.gov',
  'speedyroute.com',
  'postal.ninja',
  'lasership.com',
  '17track.net',
  'sidehusl.com',
  'track-pod.com',
  'workwave.com',
  'gridwise.io',
  'onlinetrackingnumbers.com',
  'therideshareguy.com',
  'posttrack.com',
  'onlineshippingcalculator.com',
  'amazon.com',
  'fountain.com',
  'track-trace.com',
  'senpex.com',
  'uspsinfo.com',
  'canadapost-postescanada.ca',
  'usps-track.us',
  'tsishipping.com',
  'redstagfulfillment.com',
  'elogii.com',
  'ontrac.com',
  '15track.com',
  'mapsdirections.info',
  'packageradar.com',
  'alltrucking.com',
  'maps.ie',
  'zeorouteplanner.com',
  'wheremy.com',
  'elextensions.com',
  'trackcourier.io',
  'japanpost.jp',
  'chilltracking.com',
  'easypost.com',
  'hyrecar.com',
  'getfareye.com',
  'create.net',
  'vacationcounts.com',
  'mapcustomizer.com',
  'shipstation.com',
  'shippingschool.com',
  'routeconsultant.com',
  'bestreferraldriver.com',
  'geekzowns.com',
  'circuit.com',
  'endicia.com',
  'simplfulfillment.com',
  'dhl.com',
  'pitneybowes.com',
  'packages24.com',
  'gsmtasks.com',
  'jobs-ups.com',
  'truckdriverssalary.com',
  'courierexpress.net',
  'dispatchtrack.com',
  'tracking-status.com',
  'dropoff.com',
  'plotaroute.com',
  'entrecourier.com',
  'tracktry.com',
]
