import clsx from 'clsx'

import type { ButtonProps } from './Button'

const iconColorMap = {
  blue: {
    primary: 'text-circuitBlue-200',
    secondary: 'text-circuitBlue-400',
    border: 'text-circuitBlue-400',
    borderless: 'text-circuitBlue-600',
  },
  gray: {
    primary: 'text-gray-200',
    secondary: 'text-gray-400',
    border: 'text-gray-400',
    borderless: 'text-gray-400',
  },
} as const

type SlotProps = Required<
  Pick<ButtonProps, 'pallet' | 'variation' | 'children'>
> & {
  position: 'start' | 'end'
}

export const Slot = ({ pallet, children, variation, position }: SlotProps) => {
  return (
    <span
      className={clsx(iconColorMap[pallet][variation], {
        'mr-1': position === 'start',
        'ml-1': position === 'end',
      })}
    >
      {children}
    </span>
  )
}
